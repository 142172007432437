/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./index.css";

import aws from "./img/aws.jpg";
import rt from "./img/rt.webp";

function App() {
  const darkColors = ["#3a0a5b", "#0c3934"];
  const [bgColor, setBgColor] = useState(darkColors[0]);

  const logos = [
    {
      src: rt,
      tooltip: "rtCamp",
      alt: "rtCamp",
    },
    {
      src: aws,
      tooltip: "AWS Community Builder",
      alt: "AWS Community Builder",
    }
  ];

  const handleDoubleClick = () => {
    setBgColor((prevColor) => {
      const currentIndex = darkColors.indexOf(prevColor);
      const nextIndex = (currentIndex + 1) % darkColors.length;
      return darkColors[nextIndex];
    });
  };

  useEffect(() => {
    const setRandomColor = () => {
      const randomIndex = Math.floor(Math.random() * darkColors.length);
      setBgColor(darkColors[randomIndex]);
    };
    setRandomColor();
  }, []);

  return (
    <div
      className="min-h-screen text-white reddit-sans"
      style={{ backgroundColor: bgColor }}
      onDoubleClick={handleDoubleClick}
    >
      <div className="max-w-3xl mx-auto lg:py-24 md:py-20 py-16 px-4">
        <header className="mb-8">
          <div className="relative inline-block mb-4">
            <img
              src="https://github.com/kartikmehta8.png"
              alt="Profile Photo"
              className="w-32 h-32 sm:w-40 sm:h-40 lg:w-48 lg:h-48 rounded-full"
            />
            <div className="absolute -bottom-2 -right-2 flex -space-x-4">
              {logos.map((logo, index) => (
                <div key={index} className="relative group">
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="w-12 h-12 rounded-full border-2 border-white transition-transform duration-200 ease-in-out hover:scale-105"
                  />
                  <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10">
                    {logo.tooltip}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:text-5xl md:text-5xl text-4xl">Kartik Mehta</div>
          <p className="lg:text-2xl md:text-2xl text-xl text-gray-300">
            software engineer
          </p>
        </header>

        <section className="mb-8 lg:text-2xl md:text-2xl text-lg">
          <h2 className="lg:text-2xl md:text-2xl text-xl mb-4 underline">
            about me
          </h2>
          <p className="mb-4">
            I am the kind of programmer who’ll gladly spend 10 hours automating
            a task I could do in 10 minutes, haha.
          </p>
          <p className="mb-4">
            I have a habit of jumping between tech stacks, roles, and
            locations—because, you know, remote work really blurs the line
            between "working" and "pretending not to."
          </p>
          <p className="mb-4">
            I believe that how you do anything is how you do everything!
          </p>
          <p className="mb-4">
            Need proof that I actually do stuff? Check out my{" "}
            <a
              href="https://drive.google.com/file/d/17uesQIRe5-oICgWr8owlohqaNn9c6ou9/view"
              target="_blank"
              rel="noopener noreferrer"
              className="italic text-indigo-300 hover:text-indigo-500"
            >
              resume.
            </a>
          </p>
          <p className="mb-4">
            The person behind everything at{" "}
            <a
              className="italic text-indigo-300 hover:text-indigo-500"
              href="https://www.tailwine.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              @tailwine_studio.
            </a>
          </p>
          <p>
            Also, we can arrange a brief meeting{" "}
            <a
              href="https://calendly.com/kartikmehta/15min"
              target="_blank"
              rel="noopener noreferrer"
              className="italic text-indigo-300 hover:text-indigo-500"
            >
              here.
            </a>
          </p>
        </section>
        <footer>
          <h2 className="lg:text-2xl md:text-2xl text-xl mb-4 underline">
            my socials
          </h2>
          <p className="mb-4 lg:text-2xl md:text-2xl text-lg">
            <a
              href="https://x.com/kartik_mehta8"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              twitter
            </a>{" "}
            //{" "}
            <a
              href="https://github.com/kartikmehta8"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              github
            </a>{" "}
            //{" "}
            <a
              href="https://www.linkedin.com/in/kartikmehta17"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin
            </a>{" "}
            //{" "}
            <a
              href="https://writer.mrmehta.in"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              hashnode
            </a>{" "}
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
